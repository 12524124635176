.multioptions {
  @include mobile {
    padding: 40px 0px 0px 0px;
  }

  &__textbox {
    padding: 120px 0px 80px 0px;
    text-align: center;
    background-color: none;

    @include mobile {
      padding: 40px 0px 0px 0px;
    }

    &__minHead {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 24px;

      > p {
        font-size: 12px;
        font-weight: 600;

        @include extra-large {
          font-size: 20px;
        }
      }
    }

    > h3 {
      font-size: 48px;
      line-height: 64px;
      margin-bottom: 40px;

      @include extra-large {
        font-size: 68px;
        margin-bottom: 32px;
      }

      @include mobile {
        font-size: 32px;
        line-height: 40px;
        font-weight: 500;
        margin-bottom: 24px;
      }
    }

    > p {
      font-size: 20px;
      color: #4b4d66;
      line-height: 34px;

      @include extra-large {
        font-size: 28px;
        line-height: 48px;
      }

      @include mobile {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }

  &__hero {
    display: flex;
    width: 100%;
    gap: 24px;

    @include extra-large {
      gap: 20px;
    }

    @include mobile;

    @include tablet {
      height: 100%;
      flex-direction: column;
      gap: 24px;
    }

    &__bx {
      width: 100%;
      height: 100%;
      border-radius: 24px;
      position: relative;
      overflow: hidden;

      .content-box {
        padding: 40px;
        z-index: 2;
        position: relative;
        @include extra-large {
          padding: 60px;
        }

        @include mobile {
          padding: 24px;
          margin: 0 auto;
        }

        &__title {
          margin-bottom: 40px;
          font-size: 20px;
          font-weight: 500;

          @include extra-large {
            margin-bottom: 60px;
            height: 42px;
          }

          @include mobile {
            height: 24px;
            margin-bottom: 32px;
          }
        }

        button {
          border: none;
          background-color: white;
          color: #070a21;
          margin-left: 16px;
          @include desktop {
            margin-left: 0px;
            margin-top: 16px;
            display: block;
          }
          @include tablet {
            margin-left: 16px;
            margin-top: 16px;
            display: inline-block;
          }

          @include mobile {
            margin-left: 0px;
            margin-top: 16px;
            display: inline-block;
            .dynamic-text {
              display: none;
            }
          }
          .dynamic-text {
            margin-left: 4px;
          }
        }
      }

      video.bg-video {
        visibility: hidden;
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        mix-blend-mode: multiply;
        pointer-events: none;
        z-index: 0;
        @include transition(opacity, visibility);
      }

      &:hover {
        cursor: default;
        video.bg-video {
          visibility: visible;
          opacity: 0.3;
        }
      }

      &__head {
        margin-bottom: 24px;

        @include extra-large {
          margin-bottom: 48px;
        }

        > p {
          font-size: 40px;
          font-weight: 500;
          line-height: 48px;
          color: #070a21;

          @include extra-large {
            font-size: 60px;
            line-height: 74px;
          }

          @include mobile {
            font-size: 24px;
            line-height: 32px;
            width: 80%;
          }
        }
      }

      &__subhead {
        margin-bottom: 80px;
        width: 80%;

        @include extra-large {
          margin-bottom: 120px;
          width: 80%;
        }

        @include mobile {
          margin-bottom: 40px;
          width: 100%;
        }

        > p {
          font-size: 16px;
          font-weight: 400;
          color: #2b2f40;
          line-height: 20px;

          @include extra-large {
            font-size: 24px;
            line-height: 34px;
          }

          @include mobile {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }

  &__boxes {
    display: flex;
    width: 100%;
    gap: 12px;
    padding: 80px 0px 120px 0px;
    background-color: white;

    @include extra-large {
      margin-top: 120px;
    }

    @include mobile {
      flex-direction: column;
      gap: 40px;
      padding: 40px 0px 80px 0px;
    }

    &__box {
      width: 100%;
      padding: 0px 36px;
      position: relative;
      scale: 1;
      cursor: pointer;

      &:nth-child(2) {
        border-left: 1px solid #070a21;
        border-right: 1px solid #070a21;

        @include mobile {
          border: none;
        }
      }

      @include mobile {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      > img {
        height: 40px;
        width: 40px;
        margin-bottom: 24px;
        border: none;

        @include extra-large {
          width: 60px;
          height: auto;
          margin-bottom: 36px;
        }
      }

      > h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        margin-bottom: 12px;
        color: #070a21;

        @include extra-large {
          font-size: 36px;
          margin-bottom: 24px;
        }

        @include mobile {
          font-size: 18px;
          line-height: 32px;
          margin-bottom: 16px;
        }
      }

      > p {
        font-size: 16px;
        line-height: 20px;
        color: #2b2f40;

        @include extra-large {
          font-size: 24px;
          line-height: 36px;
        }

        @include mobile {
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          text-align: center;
        }
      }
    }
  }
}

.box-1-bg {
  background-color: #c0daff;
}

.box-2-bg {
  background-color: #dfecff;

  img {
    padding-bottom: 4px;
  }
}

.btn--big--main {
  font-size: 1.8rem;
  font-weight: 100;
  line-height: 2.4rem;
  padding: 1.2rem 6rem;

  @include extra-large {
    font-size: 2.8rem;
    padding: 2.4rem 10rem;
  }
}

#multioptions__container {
  background-color: white;
  width: 100%;
  height: fit-content;
}
