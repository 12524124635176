$base-resolution: 1440;
$browser-context: 16; // Default

$vw-viewport: 1464;
@function res($size){
  $vw-context: $vw-viewport * 0.01 * 1px;
  @return $size / $vw-context * 1vw;
  @return $size;
}
@mixin extra-large {
  @media screen and (min-width: 2048px) {
    @content;
  }
}
@mixin large {
  @media screen and (max-width: 1600px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 1100px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin mobile-xs {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin min-tablet {
  @media screen and (min-width: em(768)) {
    @content;
  }
}

@mixin min-desktop {
  @media screen and (min-width: em(1025)) {
    @content;
  }
}

@mixin media-custom-max($size) {
  @media screen and (max-width: em($size)) {
    @content;
  }
}
/*
 Usage:   @include transition(width, height 0.3s ease-in-out);
 Output:  -webkit-transition(width 0.2s, height 0.3s ease-in-out);
          transition(width 0.2s, height 0.3s ease-in-out);

 Pass in any number of transitions
 */
@mixin transition($transitions...) {
  $unfoldedTransitions: ();
  @each $transition in $transitions {
    $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
  }
  -webkit-transition: $unfoldedTransitions;
  -moz-transition: $unfoldedTransitions;
  -ms-transition: $unfoldedTransitions;
  -o-transition: $unfoldedTransitions;
  transition: $unfoldedTransitions;
}

@function unfoldTransition ($transition) {
  /* Default values

  */
  $property: all;
  $duration: .3s;
  $easing: cubic-bezier(0.25, 0.46, 0.45, 0.94); /*// Browser default is ease, which is what we want*/
  $delay: null; /*// Browser default is 0, which is what we want*/
  $defaultProperties: ($property, $duration, $easing, $delay);

  /* Grab transition properties if they exist
   */
  $unfoldedTransition: ();
  @for $i from 1 through length($defaultProperties) {
    $p: null;
    @if $i <= length($transition) {
      $p: nth($transition, $i)
    } @else {
      $p: nth($defaultProperties, $i)
    }
    $unfoldedTransition: append($unfoldedTransition, $p);
  }

  @return $unfoldedTransition;
}
