.streamlined {
  @include mobile {
    padding: 40px 0px 0px 0px;
  }

  &__textbox {
    padding: 0px 0px 60px 0px;
    text-align: center;
    background-color: none;
    @include mobile {
      padding: 40px 0px 40px 0px;
    }
    &__minHead {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      gap: 8px;
      margin-bottom: 24px;
      > p {
        font-size: 12px;
        font-weight: 600;
        @include extra-large {
          font-size: 20px;
        }
      }
    }
    > h3 {
      font-size: 48px;
      line-height: 64px;
      margin-bottom: 40px;
      @include extra-large {
        font-size: 68px;
        margin-bottom: 32px;
      }
      @include mobile {
        font-size: 32px;
        line-height: 40px;
        font-weight: 500;
        margin-bottom: 24px;
      }
    }
    > p {
      font-size: 20px;
      color: #4b4d66;
      line-height: 34px;
      @include extra-large {
        font-size: 28px;
        line-height: 48px;
      }
      @include mobile {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
}

.streamlined-section {
  padding: 40px 0 80px 0;
  background-color: #f8f9fa;

  @include mobile {
    padding: 20px 0 40px 0;
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .streamlined-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .streamlined-badge {
    display: inline-flex;
    align-items: center;
    color: #3366ff;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;

    .streamlined-icon {
      margin-right: 6px;
    }

    .streamlined-text {
      letter-spacing: 1px;
    }
  }

  .streamlined-title-container {
    @media (min-width: 768px) {
      text-align: left;
      max-width: 30%;
    }
  }

  .streamlined-title {
    font-size: 42px;
    font-weight: 700;
    color: #111;
    margin: 0 0 16px;
  }

  .streamlined-description {
    font-size: 18px;
    color: #555;
    line-height: 1.5;
    margin: 0;
  }

  .features-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 992px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .desktop-features {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .streamlined-slider-container {
    @media (min-width: 768px) {
      display: none;
    }

    position: relative;
    margin-bottom: 40px;
  }

  .streamlined-slider {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .streamlined-slider-buttons {
    margin-top: 24px;
    display: flex;
    justify-content: center;

    .slider-nav {
      display: flex;
      gap: 16px;

      &__btn {
        width: auto;
        height: auto;
        background-color: transparent;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease;

        img {
          width: 24px;
          height: auto;
        }
      }
    }
  }

  .swiper-slide {
    height: auto;
    padding: 8px;
  }

  .feature-card {
    background-color: #dfecff;
    border-radius: 24px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 325px;
    height: 100%;

    .feature-title {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.75px;
      color: #111;
    }

    .feature-description {
      font-size: 16px;
      color: #555;
      line-height: 1.6;
      margin: 0 0 24px;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 1.5;
      }
    }

    .feature-links {
      display: flex;
      gap: 12px;
    }

    .btn {
      display: inline-flex;
      align-items: center;
      gap: 8px;
      padding: 6px 16px;
      border-radius: 200px;
      border: 1px solid #424cea;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.2s ease;

      .btn-icon {
        margin-right: 6px;
      }

      &.btn-docs {
        color: #424cea;

        &:hover {
          background-color: #f5f8ff;
        }
      }

      &.btn-github {
        color: #424cea;

        &:hover {
          background-color: #f5f8ff;
        }
      }
    }
  }

  .streamlined-cta-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0 0 0;

    @media (max-width: 767px) {
      padding: 0;
    }
  }
}

#streamlined__container {
  background-color: white;
  width: 100%;
  height: fit-content;
}
