.view-documentation {
  &__wrap {
    text-align: center;
    border-radius: 1rem;
    padding: 3.2rem 3.2rem 4rem 3.2rem;
    background-color: #f5f6fe;
    margin-top: 12rem;

    @include mobile {
      margin-top: 8rem;
    }

    @include extra-large{
      padding: 4rem 4rem  6rem 4rem;
    }
  }

  svg {
    margin-bottom: 3.2rem;

    @include extra-large{
      width: 120px;
    }
  }

  h5 {
    color: #101828;
    font-family: Poppins;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 3rem;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 1rem;

    @include extra-large{
      font-size: 3.6rem;
      line-height: 6rem;
    }

    @include mobile {
      font-size: 1.75rem;
      line-height: 3rem;
    }
  }

  p {
    font-family: Poppins;
    font-size: 1.65rem;
    line-height: 2.5rem;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    color: #475467;
    max-width: 76.8rem;
    margin: 0 auto;

    @include extra-large{
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    @include mobile {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }
  }
}
